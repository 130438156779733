const isMobile = () => window.matchMedia("(max-width: 960px)").matches;
const isLocalhost = () => location.hostname === "localhost" || location.hostname === "127.0.0.1";

const localhostFunctions = 'http://localhost:5001/ndesk-site/us-central1';
const onlineFunctions = 'https://us-central1-ndesk-site.cloudfunctions.net';

(global => {
  let wasMobile = isMobile();
  var throttle = function (type, name, obj) {
    obj = obj || global;
    var running = false;
    var func = function () {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(function () {
        const isMob = isMobile();
        if (wasMobile !== isMob) {
          obj.dispatchEvent(new CustomEvent(name));
          wasMobile = isMob;
        }
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };

  throttle("resize", "breakpointChanged");
})(window);

(global => {
  const container = document.querySelector(".screenshot");
  const vid = document.querySelector("video");
  const pkgJson = document.querySelector(".package-json-file");
  const screenshot = document.querySelector(".background-screenshot");
  const showMeAgain = document.querySelector(".show-me-again");
  const macDownload = document.querySelectorAll(".mac-download");
  const windowsDownload = document.querySelectorAll(".windows-download");

  if (pkgJson) {
    pkgJson.src = "images/package-json-file.svg";
  }
  if (screenshot) {
    screenshot.src = "images/screenshot-empty-2.png";
  }
  if (vid) {
    vid.src = "images/video3.mp4";
  }

  if (macDownload) {
    Array.from(macDownload).forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault()
        window.location = 'https://download.ndesk.app/mac'
      })
    })
  }

  if (windowsDownload) {
    Array.from(windowsDownload).forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault()
        window.location = 'https://download.ndesk.app/windows'
      })
    })
  }

  let vidPlaying = false;
  let listenersLoaded = false;

  if (isMobile()) {
    // Do Nothing
  } else {
    loadListeners();
  }

  window.addEventListener(
    "breakpointChanged",
    () => {
      if (isMobile() || listenersLoaded) return;
      loadListeners();
      checkPlay();
    },
    false
  );

  function loadListeners() {
    listenersLoaded = true;
    vid.addEventListener("canplaythrough", () => checkPlay());
    vid.addEventListener("loadeddata", () => checkPlay());
    pkgJson.addEventListener("load", () => checkPlay());
    screenshot.addEventListener("load", () => checkPlay());
    vid.addEventListener("ended", () => {
      pkgJson.classList.remove("animate");
      setTimeout(() => {
        showMeAgain.classList.add("visible");
        setTimeout(() => {
          showMeAgain.classList.add("rubberBand");
        }, 400);
      }, 600);
    });

    [showMeAgain].map(x =>
      x.addEventListener("mouseover", () => {
        container.style.filter = "blur(2px)";
      })
    );

    [showMeAgain].map(x =>
      x.addEventListener("mouseout", () => {
        container.style.filter = "";
      })
    );

    showMeAgain.addEventListener("click", () => {
      container.style.filter = "";
      playVideo();
    });
  }

  function imgLoaded(imgElement) {
    return imgElement.complete && imgElement.naturalHeight !== 0;
  }

  function checkPlay() {
    const vidReady = vid.readyState === 4;
    const readyToPlay =
      !vidPlaying && vidReady && imgLoaded(screenshot) && imgLoaded(pkgJson);
    if (readyToPlay) {
      playVideo();
    }
  }

  function playVideoNow() {
    vid.play();
    pkgJson.removeEventListener("animationend", playVideoNow);
  }

  function playVideo() {
    vidPlaying = true;
    showMeAgain.classList.remove("visible");
    container.classList.add("visible");
    vid.classList.add("visible");
    vid.pause();
    vid.currentTime = 0;
    setTimeout(() => {
      pkgJson.classList.add("animate");
      pkgJson.addEventListener("animationend", playVideoNow);
    }, 1000);
  }
})(window);
